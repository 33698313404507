import {
  IDatePicker,
  IWedding,
  IWedding_Task,
  IWedding_Website,
} from '@bridebook/models/source/models/Weddings.types';
import { mapDateToUI } from '@bridebook/toolbox';
import { ICollaborator } from 'lib/access-control/types';
import {
  IWeddingWebsiteDesignTabsState,
  IWeddingWebsiteOnboardingState,
} from 'lib/wedding-website/types';

export type UIDate = ReturnType<typeof mapDateToUI>;

export interface IProfile extends Omit<IWedding, 'tasks' | 'createdAt' | 'updatedAt'> {
  id: string;
  date?: IDatePicker;
  tasks: Omit<IWedding_Task, 'initializedAt'> & { initializedAt?: number };
  engagementDateDatePicker?: IDatePicker;
  weddingDate?: string;
}

export interface IWeddingVenue {
  name: string;
  collection: 'suppliers';
  publicID?: string;
  id: string;
  town: string;
}

export interface ILatLongBounds {
  ne: { lat: number; lon: number };
  sw: { lat: number; lon: number };
}

export interface IWeddingWebsiteDataState {
  dirty: boolean;
  shouldPrefill: boolean;
  draft: {
    wedding: {
      partners: [string, string];
      weddingDate: IDatePicker;
    };
    website: IWedding_Website;
  };
  onboarding: IWeddingWebsiteOnboardingState;
  design: IWeddingWebsiteDesignTabsState;
}

export interface IWeddingsState {
  profile: IProfile;
  collaborators: Array<ICollaborator>;
  loaded: boolean;
  countryLatLongBounds: ILatLongBounds;
  listenersInitialised: boolean;
  inboxUnread: number;
  loading: boolean;
  websiteMeta: IWeddingWebsiteDataState;
}

export enum PartnerRoles {
  bride = 'bride',
  groom = 'groom',
  other = 'other',
}
