import { User, getAuth } from 'firebase/auth';

type TAuthenticatedFetch = <TResponseBody = any>(
  url: RequestInfo,
  init?: Omit<RequestInit, 'headers'> & { headers?: Headers },
) => Promise<Omit<Response, 'json'> & { json(): Promise<TResponseBody> }>;

export const getCurrentUser: () => Promise<User | null> = () =>
  new Promise((resolve, reject) => {
    const currentUser = getAuth().currentUser;
    if (currentUser) {
      resolve(currentUser);
    } else {
      const unsubscribe = getAuth().onAuthStateChanged((user) => {
        unsubscribe();
        resolve(user);
      }, reject);
    }
  });

export const authenticatedFetch: TAuthenticatedFetch = async (url, init) => {
  const headers = init?.headers ? init.headers : new Headers();
  const currentUser = await getCurrentUser();
  if (currentUser) {
    headers.append('Authorization', 'Bearer ' + (await currentUser.getIdToken()));
  }
  return await fetch(url, {
    ...init,
    headers,
  });
};

/*
 * Vercel sometimes returns a 504 HTTP status with a text body instead of JSON.
 * This method attempts to parse the response as JSON. If that fails it falls back to parsing the response as text.
 * */
export const parseResponseBody = async (response: Response) => {
  const responseBody = await response.text();
  try {
    return JSON.parse(responseBody);
  } catch (e) {
    return {
      message: responseBody,
    };
  }
};

/**
 * Small helper to fetch by POST with default application/json headers.
 * Stringifies body automatically.
 * Returns JSON response or throws an error on failed fetch.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const authenticatedPOST = async <Body extends Record<string, any>, Res = unknown>(
  url: RequestInfo,
  req: Omit<RequestInit, 'body'> & { body?: Body | null } = {},
  errorMsg?: string,
): Promise<Res> => {
  const headers = new Headers({
    'Content-Type': 'application/json',
    ...req?.headers,
  });

  // @ts-ignore
  const query = await authenticatedFetch(url, {
    /** We are slightly changing the body property and therefore need to cast here */
    ...(req as unknown as RequestInit),
    method: 'POST',
    headers,
    ...(req.body && { body: JSON.stringify(req.body) }),
  });
  const responseBody = await parseResponseBody(query);

  if (query.status !== 200) {
    throw new Error(
      errorMsg ||
        `Error (${query.status} - ${query.statusText}) while fetching ${url}:\n${responseBody.message}`,
    );
  }

  return responseBody;
};

/**
 * Small helper to fetch by GET
 * Returns JSON response or throws an error on failed fetch.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const authenticatedGET = async <Res extends Record<string, any> | null | void>(
  url: RequestInfo,
  req: Omit<RequestInit, 'headers' | 'body'> & {
    headers?: Headers;
  } = {},
  errorMsg?: string,
): Promise<Res> => {
  const query = await authenticatedFetch(url, {
    ...req,
    method: 'GET',
    ...(req?.headers && { headers: new Headers(req.headers) }),
  });

  const response = await query.json();

  if (query.status !== 200) {
    throw new Error(
      errorMsg ||
        `Error (${query.status} - ${query.statusText}) while fetching ${url}:\n${response.message}`,
    );
  }

  return response;
};

/**
 * Helper for fetching data from a BB API endpoint using
 * the server to server API key.
 */
const API_KEY = process.env.BB_API_KEY;
export const authenticatedApiFetch: TAuthenticatedFetch = async (url, init) => {
  const headers = init?.headers ? init.headers : new Headers();
  headers.append('Authorization', 'Bearer ' + API_KEY);

  return await fetch(url, {
    ...init,
    headers,
  });
};
