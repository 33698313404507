import { equals, pathOr } from 'ramda';
import { createSelector } from 'reselect';
import {
  IAddress,
  IWedding,
  IWedding_Website_Section,
  IWedding_Website_Section_PhotosField,
  IWedding_Website_Section_QuestionAnswerField,
  IWedding_Website_Section_TextField,
  IWedding_Website_Section_VenueField,
} from '@bridebook/models/source/models/Weddings.types';
import gazetteer, { CountryCodes, Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import {
  ADMINISTRATIVE_AREAS_LEGACY,
  getCountyAdminAreaField,
} from '@bridebook/toolbox/src/search-suppliers/elastic-queries/utils';
import type { Slug } from '@bridebook/toolbox/src/types';
import { extractLocationName } from 'app-shared/lib/search/utils/utils';
import { getAreaContext } from 'app-shared/lib/supplier/utils/get-location-context';
import { getCountryCodeWithFallback, getLocalisation } from 'lib/i18n/selectors';
import { getWeddingVenue } from 'lib/shortlist/selectors';
import { IApplicationState } from 'lib/types';
import { toTitleCase } from 'lib/utils';
import { createDeepEqualSelector } from 'lib/utils/selectors';
import { IWeddingsState, PartnerRoles } from 'lib/weddings/types';

const _getWedding = (state: IApplicationState): IWeddingsState => state.weddings;
const _getWeddingLocation = (state: IApplicationState) => state.weddings.profile.location;

const _getWeddings = (state: IApplicationState) => state.weddings;
export const getBookings = (state: IApplicationState): IWedding['bookings'] =>
  state.weddings.profile.bookings;
const profileLoaded = (state: IApplicationState): boolean => state.weddings.loaded;
export const getIsProfileLoading = (state: IApplicationState): boolean => state.weddings.loading;
const date = (state: IApplicationState) => state.weddings.profile.date;
const partners = (state: IApplicationState) => state.weddings.profile.partners;
export const getWeddingId = (state: IApplicationState) => state.weddings.profile.id;
export const getWeddingCollaborators = (state: IApplicationState) => state.weddings.collaborators;
export const getWeddingUsers = (state: IApplicationState) => state.weddings.profile.users;
export const getInboxUnreadCount = (state: IApplicationState) => state.weddings.inboxUnread;
export const getWeddingLocationName = (state: IApplicationState) => getWeddingLocation(state)?.name;
const roles = (state: IApplicationState) => state.weddings.profile.roles;
export const getProfile = (state: IApplicationState) => state.weddings.profile;
export const getProfileNotesCount = (state: IApplicationState) =>
  state.weddings.profile?.notes?.count;

/* ############################################################################
 *  MEMOIZED SELECTORS
 * ######################################################################### */

export const getWeddingLocation = createDeepEqualSelector(
  [_getWeddingLocation],
  (weddingLocation) => weddingLocation,
);

export const getWeddingCountryBounds = createSelector(
  [_getWedding],
  (wedding) => wedding.countryLatLongBounds,
);

/** used in onboarding where we need all 3 states of this property:
 * unspecified (undefined);
 * booked (true);
 * not booked (false);
 */
export const getVenueBookedUndefined = createSelector(
  [getBookings],
  (bookings: IWedding['bookings']): boolean | undefined => pathOr(undefined, ['venue'], bookings),
);

export const getVenueBooked = createSelector(
  [getBookings],
  (bookings: IWedding['bookings']): boolean => pathOr(false, ['venue'], bookings),
);

export const getProfileLoaded = createSelector(
  [profileLoaded],
  (profileLoaded: boolean): boolean => profileLoaded,
);

export const getPartners = createDeepEqualSelector([partners], (partners) => partners);

export const getIsMissingPartners = createSelector([partners], (partners) => partners.includes(''));

export const getWeddingDate = createDeepEqualSelector(
  date,
  // deep compare new date object with cache
  (date) => date,
);

export const extractFormattedLocationName = (
  location: IAddress | null,
  countryCode: CountryCodes,
) => {
  if (location?.name) {
    return toTitleCase(extractLocationName(location)).replace(/-/g, ' ');
  }
  if (location?.adminArea?.[0] && location?.adminArea?.[1]) {
    // if no town or location name, return value that is used for county search. Germany uses country field for that.
    const countyField = getCountyAdminAreaField(countryCode, true);
    if (countyField === ADMINISTRATIVE_AREAS_LEGACY.ADMIN_AREA_1) {
      return location.adminArea[1];
    } else if (countyField === ADMINISTRATIVE_AREAS_LEGACY.ADMIN_AREA_2) {
      return location.adminArea[0];
    }
  }
  return null;
};

/**
 * Returns formatted location of a booked venue if exists or from the wedding profile.
 * TODO: [i18n][bb-global] Check is `location?.adminArea?.[1]` is correct for US. Likely not.
 */
export const getVenueOrWeddingLocation = createSelector(
  [(state) => getWeddingVenue(state), getWeddingLocation, getCountryCodeWithFallback],
  (weddingVenue, location, countryCode): string | null => {
    /**
     * TODO: [i18n] For US, we are not getting the most specific area, but just the state. 🤔
     */
    if (location?.country === CountryCodes.US && location?.adminArea?.[1] != null) {
      return location?.adminArea?.[1];
    }

    if (weddingVenue?.town) {
      return weddingVenue.town;
    }

    return extractFormattedLocationName(location, countryCode);
  },
);

/**
 * Returns formatted location of a booked venue (with it's name) if exists or from the wedding
 * profile.
 */
export const getVenueOrWeddingLocationUIString = createSelector(
  [(state) => getWeddingVenue(state), getWeddingLocation],
  (weddingVenue, location): string | null => {
    if (weddingVenue) {
      const { name = '', town } = weddingVenue;
      return town ? `${name}, ${town}` : name;
    }
    if (location?.name) {
      return toTitleCase(extractLocationName(location)).replace(/-/g, ' ');
    }
    return null;
  },
);

/**
 * Use booked venue or wedding location name first, then fallback to US whitelisted state, and
 * lastly fallback to wedding country name
 */
export const getLocationName = createSelector(
  [getVenueOrWeddingLocation, getLocalisation],
  (venueOrWeddingLocation, l10n): string =>
    venueOrWeddingLocation
      ? venueOrWeddingLocation
      : l10n?.country
        ? Gazetteer.getCountryName(l10n.country) || ''
        : '',
);

/**
 * Try to get most appropriate location context (country in this case)
 */
export const getLocationContext = createSelector(
  [getWeddingLocation, getCountryCodeWithFallback],
  (location, countryCode): string[] => getAreaContext(location, countryCode),
);

export const getDefaultSearchParams = createSelector(
  [getLocationName, getLocationContext],
  (area, areaContext) => ({
    slug: 'venue' as Slug,
    area,
    areaContext,
    filters: {},
  }),
);

export const isBrideOnlyWedding = createSelector([roles], (roles = []) =>
  equals(roles, [PartnerRoles.bride, PartnerRoles.bride]),
);

export const isGroomOnlyWedding = createSelector([roles], (roles = []) =>
  equals(roles, [PartnerRoles.groom, PartnerRoles.groom]),
);

export const isSameSexWedding = createSelector(
  [isBrideOnlyWedding, isGroomOnlyWedding],
  (bridesOnly, groomsOnly) => bridesOnly || groomsOnly,
);

export const getChecklistInitializationDate = createSelector(getProfile, (profile) =>
  profile ? new Date(profile.tasks.initializedAt || 0).toISOString() : undefined,
);

export const getWeddingProfileId = createSelector(getProfile, ({ id }) => id);

export const getWeddingBudget = createSelector(getProfile, (profile) => profile.budget);
export const getEstimatedGuests = createSelector(getProfile, (profile) => profile.guests.estimate);
export const getAddedGuests = createSelector(getProfile, (profile) => profile.guests.count);
export const getWeddingCurrency = createSelector(getProfile, (profile) => profile.l10n?.currency);
export const selectHasCollaborators = createSelector(
  getWeddingUsers,
  (weddingUsers) => weddingUsers.length > 1,
);

/**
 * Determines if wedding location is generic (United Kingdom or Deutschland for example) or not.
 */
export const getHasGenericWeddingLocation = createSelector(getWeddingLocation, (location) => {
  const countries = gazetteer
    .getMarkets()
    .map((market) => market.getCountryName().toLocaleLowerCase());

  return !!(location?.name && countries.includes(location.name.trim().toLocaleLowerCase()));
});

export const getWeddingMicrosite = createSelector(getProfile, (profile) => profile.microsite);

// TODO: determine if needed to properly suggest places since Market exists, to revisit after discussing the issue with wedding locations vs google places @adgutbb
export const getCountryLatLongBounds = createSelector(
  _getWeddings,
  (weddings) => weddings.countryLatLongBounds,
);

/* ############################################################################
 *  WEBSITE DATA DRAFT SELECTORS
 * ######################################################################### */

const _getWeddingWebsiteData = (state: IApplicationState) => state.weddings.websiteMeta;

export const getWebsiteDirty = createSelector([_getWeddingWebsiteData], (state) => state.dirty);

export const getWebsiteShouldPrefill = createSelector(
  [_getWeddingWebsiteData],
  (state) => state.shouldPrefill,
);

export const getWebsiteDraft = createSelector([_getWeddingWebsiteData], (state) => state.draft);

export const getWebsitePartners = createSelector(
  [_getWeddingWebsiteData],
  (state) => state.draft.wedding.partners,
);

export const getWebsiteWeddingDate = createSelector(
  [_getWeddingWebsiteData],
  (state) => state.draft.wedding.weddingDate,
);

export const getWebsiteTheme = createSelector(
  [_getWeddingWebsiteData],
  (state) => state.draft.website.config.theme,
);

export const getWebsiteThemeFont = createSelector(
  [_getWeddingWebsiteData],
  (state) => state.draft.website.config.theme.font,
);

export const getWebsiteThemePalette = createSelector(
  [_getWeddingWebsiteData],
  (state) => state.draft.website.config.theme.palette,
);

export const getWebsitePin = createSelector(
  [_getWeddingWebsiteData],
  (state) => state.draft.website.privacy.pin,
);

export const getWebsitePublished = createSelector(
  [_getWeddingWebsiteData],
  (state) => state.draft.website.privacy.published,
);

export const getWebsiteSections = createSelector(
  [_getWeddingWebsiteData],
  (state) => state.draft.website.sections,
);

const _getWebsiteSection = <T>(sectionName: string, type: IWedding_Website_Section['type']) =>
  createSelector([_getWeddingWebsiteData], (state) => {
    const section = state.draft.website.sections.find((section) => section.name === sectionName);
    if (section && section.type === type) {
      return section as T;
    }
    return null;
  });

export const getWebsiteSection = {
  VenueField: (sectionName: string) =>
    _getWebsiteSection<IWedding_Website_Section_VenueField>(sectionName, 'VenueField'),
  PhotosField: (sectionName: string) =>
    _getWebsiteSection<IWedding_Website_Section_PhotosField>(sectionName, 'PhotosField'),
  QuestionAnswerField: (sectionName: string) =>
    _getWebsiteSection<IWedding_Website_Section_QuestionAnswerField>(
      sectionName,
      'QuestionAnswerField',
    ),
  TextField: (sectionName: string) =>
    _getWebsiteSection<IWedding_Website_Section_TextField>(sectionName, 'TextField'),
};

/* ############################################################################
 *  WEBSITE ONBOARDING SELECTORS
 * ######################################################################### */

const _getWeddingWebsitesOnboarding = (state: IApplicationState) =>
  state.weddings.websiteMeta.onboarding;

export const getWeddingWebsitesOnboardingOpen = createSelector(
  [_getWeddingWebsitesOnboarding],
  (state) => state.open,
);

export const getWeddingWebsitesOnboardingActiveStep = createSelector(
  [_getWeddingWebsitesOnboarding],
  (state) => state.activeStep,
);

/* ############################################################################
 *  WEBSITE DESIGN SELECTORS
 * ######################################################################### */

const _getWeddingWebsiteDesign = (state: IApplicationState) => state.weddings.websiteMeta.design;

export const getWebsiteDesignThemeTabOpen = createSelector(
  [_getWeddingWebsiteDesign],
  (state) => state.isThemesOpen,
);

export const getWebsiteDesignCustomiseTabOpen = createSelector(
  [_getWeddingWebsiteDesign],
  (state) => state.isCustomiseOpen,
);
